import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#86A3B1',
  light: '#537281',
  main: '#2B3B43',
  dark: '#212D33',
  darker: '#161E22',
};
const SECONDARY = {
  lighter: '#F7B6A6',
  light: '#F3927A',
  main: '#EE6C4D',
  dark: '#D93C14',
  darker: '#91280D',
};
const INFO = {
  lighter: '#AEC499',
  light: '#85A766',
  main: '#5E7845',
  dark: '#465A34',
  darker: '#2F3C22',
};
const SUCCESS = {
  lighter: '#AEC499',
  light: '#85A766',
  main: '#5E7845',
  dark: '#465A34',
  darker: '#2F3C22',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#F7B6A6',
  light: '#F3927A',
  main: '#EE6C4D',
  dark: '#D93C14',
  darker: '#91280D',
};

const IVORY = {
  lighter: '#FDF9FA',
  main: '#FCF7F8'
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#FCF7F8' },
  secondary: { ...SECONDARY, contrastText: '#FCF7F8' },
  info: { ...INFO, contrastText: '#FCF7F8' },
  success: { ...SUCCESS, contrastText: '#FCF7F8' },
  warning: { ...WARNING, contrastText: '#FCF7F8' },
  error: { ...ERROR, contrastText: '#FCF7F8' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hoverOpacity: 0.2,
    disabledOpacity: 0.48,
    selectedOpacity: 0.5
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: PRIMARY.main, secondary: PRIMARY.darker, disabled: GREY[500] },
    background: { paper: IVORY.lighter, default: IVORY.main, neutral: GREY[200], accent: INFO.main },
    action: { active: SECONDARY.main, hover: alpha(INFO.main, COMMON.action.hoverOpacity), selected: PRIMARY.main, disabled: PRIMARY.main, disabledBackground: PRIMARY.main, focus: PRIMARY.main, ...COMMON.action }
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: IVORY.main, secondary: IVORY.lighter, disabled: SECONDARY.main },
    background: { paper: PRIMARY.darker, default: PRIMARY.main, neutral: SECONDARY.main, accent: SECONDARY.main },
    action: { active: SECONDARY.main, hover: alpha(SECONDARY.main, COMMON.action.hoverOpacity), selected: SECONDARY.main, disabled: SECONDARY.main, disabledBackground: SECONDARY.main, focus: SECONDARY.main, ...COMMON.action }
  },
};

export default palette;
